<template>
    <div class="page area fade-in">
        <transition appear>
            <div class="area-modal">
                <close-btn @click.stop="close"></close-btn>
                <div class="area-sidebar" v-if="contentPage">
                    <div class="area-title">{{contentPage.post_title}}</div>
                    <nav class="nav scrollable-y">
                        <ul class="list-menu">
                            <template v-if="landmarkGroups">
                                <li v-for="(landmarkGroup, lmgi) in landmarkGroups" :key="lmgi">
                                    <a class="list-menu-item" :class="{visible: this.activeLMG[lmgi]}" href="javascript:void(0)" @click="selectLandmarkGroup(lmgi, landmarkGroup)">
																				<fp-icon v-if="landmarkGroup.animated_button_icon" :animated="landmarkGroup.animated_button_icon" target=".list-menu-item"></fp-icon>
																				<menu-icon v-else-if="landmarkGroup.button_icon"
																						:src="landmarkGroup.button_icon"
																						:alt="landmarkGroup.label"
																				></menu-icon>
                                        <span>{{landmarkGroup.label}}</span>
                                    </a>
                                </li>
                            </template>
                            <template v-for="(poi, poiIndex) in customStyle" :key="poiIndex">
                                <li v-if="poi.showInMenu">
                                    <a
                                        class="list-menu-item"
																				:class="[`poi-${poiIndex}`, this.activePOI && this.activePOI[poiIndex] ? 'visible': 'invisible']"
                                        href="javascript:void(0)"
                                        @click="filter(poiIndex)"
                                    >
                                        {{poi.label}}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </nav>
                </div>
                <div class="area-content">
                    <google-map
                        v-if="contentPage"
                        ref="googleMap"
                        :options="mapOptions"
                        :landmark-groups="landmarkGroups"
                        :center="center"
                        :zoom="zoom"
												:map-type="mapType"
                    ></google-map>
                    <div v-if="loading">
                        <loading-data></loading-data>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn"
import GoogleMap from "@/components/GoogleMap"
import {mapGetters} from "vuex"
import LoadingData from "@/components/LoadingData"
import FpIcon from "@/components/FpIcon";
import MenuIcon from "@/components/MenuIcon";

/*const mapStyle = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#1b1b1b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2c2c2c"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#373737"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#3c3c3c"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
]*/

const mapStyleSilver = [
		{
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#f5f5f5"
						}
				]
		},
		{
				"elementType": "labels.icon",
				"stylers": [
						{
								"visibility": "off"
						}
				]
		},
		{
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#616161"
						}
				]
		},
		{
				"elementType": "labels.text.stroke",
				"stylers": [
						{
								"color": "#f5f5f5"
						}
				]
		},
		{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#bdbdbd"
						}
				]
		},
		{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#eeeeee"
						}
				]
		},
		{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#757575"
						}
				]
		},
		{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#e5e5e5"
						}
				]
		},
		{
				"featureType": "poi.park",
				"elementType": "geometry.fill",
				"stylers": [
						{
								"color": "#98c89b"
						},
						{
								"visibility": "on"
						}
				]
		},
		{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#9e9e9e"
						}
				]
		},
		{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#ffffff"
						}
				]
		},
		{
				"featureType": "road",
				"elementType": "geometry.stroke",
				"stylers": [
						{
								"color": "#fafffb"
						},
						{
								"visibility": "on"
						}
				]
		},
		{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#757575"
						}
				]
		},
		{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#dadada"
						}
				]
		},
		{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#616161"
						}
				]
		},
		{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#9e9e9e"
						}
				]
		},
		{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#e5e5e5"
						}
				]
		},
		{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#eeeeee"
						}
				]
		},
		{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#c9c9c9"
						}
				]
		},
		{
				"featureType": "water",
				"elementType": "geometry.fill",
				"stylers": [
						{
								"color": "#82d8e3"
						},
						{
								"visibility": "on"
						}
				]
		},
		{
				"featureType": "water",
				"elementType": "geometry.stroke",
				"stylers": [
						{
								"color": "#82d7e3"
						},
						{
								"visibility": "on"
						}
				]
		},
		{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#9e9e9e"
						}
				]
		}
];

/*const mapStyle = [
		{
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#ebe3cd"
						}
				]
		},
		{
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#523735"
						}
				]
		},
		{
				"elementType": "labels.text.stroke",
				"stylers": [
						{
								"color": "#f5f1e6"
						}
				]
		},
		{
				"featureType": "administrative",
				"elementType": "geometry.stroke",
				"stylers": [
						{
								"color": "#c9b2a6"
						}
				]
		},
		{
				"featureType": "administrative.land_parcel",
				"elementType": "geometry.stroke",
				"stylers": [
						{
								"color": "#dcd2be"
						}
				]
		},
		{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#ae9e90"
						}
				]
		},
		{
				"featureType": "landscape.natural",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#dfd2ae"
						}
				]
		},
		{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#dfd2ae"
						}
				]
		},
		{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#93817c"
						}
				]
		},
		{
				"featureType": "poi.park",
				"elementType": "geometry.fill",
				"stylers": [
						{
								"color": "#a5b076"
						}
				]
		},
		{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#447530"
						}
				]
		},
		{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#f5f1e6"
						}
				]
		},
		{
				"featureType": "road.arterial",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#fdfcf8"
						}
				]
		},
		{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#f8c967"
						}
				]
		},
		{
				"featureType": "road.highway",
				"elementType": "geometry.stroke",
				"stylers": [
						{
								"color": "#e9bc62"
						}
				]
		},
		{
				"featureType": "road.highway.controlled_access",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#e98d58"
						}
				]
		},
		{
				"featureType": "road.highway.controlled_access",
				"elementType": "geometry.stroke",
				"stylers": [
						{
								"color": "#db8555"
						}
				]
		},
		{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#806b63"
						}
				]
		},
		{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#dfd2ae"
						}
				]
		},
		{
				"featureType": "transit.line",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#8f7d77"
						}
				]
		},
		{
				"featureType": "transit.line",
				"elementType": "labels.text.stroke",
				"stylers": [
						{
								"color": "#ebe3cd"
						}
				]
		},
		{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
						{
								"color": "#dfd2ae"
						}
				]
		},
		{
				"featureType": "water",
				"elementType": "geometry.fill",
				"stylers": [
						{
								"color": "#b9d3c2"
						}
				]
		},
		{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
						{
								"color": "#92998d"
						}
				]
		}
]*/
export default {
    name: "AreaPage",
    components: {MenuIcon, FpIcon, LoadingData, GoogleMap, CloseBtn},
    computed: {
				mapType(){
						if(this.contentPage && this.contentPage.map && this.contentPage.map.map_type){
								return this.contentPage.map.map_type
						}
						return null
				},
        center(){
            if(this.contentPage && this.contentPage.map && this.contentPage.map.center_coordinates){
                return this.contentPage.map.center_coordinates
            }
            return null
        },
        zoom(){
            if(this.contentPage && this.contentPage.map){
                if(this.isMobile && this.contentPage.map.zoom_mobile){
                    return this.contentPage.map.zoom_mobile
                }else if(this.contentPage.map.zoom){
                    return this.contentPage.map.zoom
                }
            }
            return null
        },
        landmarkGroups(){
            if(this.contentPage && this.contentPage.map){
                const landmark_groups = [];
                const map = this.contentPage.map
                if(map.center_coordinates_icon && map.show_center_landmark && map.center_coordinates){
                    landmark_groups.unshift({
                        label: map.center_label || 'מרכז',
                        icon: map.center_coordinates_icon,
                        landmarks: [
                            {
                                address: map.center_address,
                                coordinates: map.center_coordinates
                            }
                        ]
                    })
                }
                if(map.landmark_groups){
                    map.landmark_groups.forEach(({landmarks, label, icon, animated_button_icon, button_icon}) => {
                        landmark_groups.push({landmarks, label, icon, animated_button_icon, button_icon})
                    })
                }
                return landmark_groups.map((lmg, lmgi) => {
                    lmg.visible = true
										this.activeLMG[lmgi] = true
                    return lmg
                })
            }
            return null
        },
        mapOptions() {
            const styles = JSON.parse(JSON.stringify(mapStyleSilver))
						if(this.customStyle){
								Object.entries(this.customStyle).forEach((poi) => {
										//const key = poi[0]
										const value = poi[1]
										styles.push(value.mapStyle)
								});
						}
            return {
                styles
            }
        },
				mapPoi(){
						return {
								all: {
										label: this.$t('הכל'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								attraction: {
										label: this.$t('אטרקציות'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.attraction",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								}    ,
								business: {
										label: this.$t('עסקים'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.business",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								business_shopping: {
										label: this.$t('חנויות'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.business.shopping",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								business_food_and_drink: {
										label: this.$t('מסעדות'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.business.food_and_drink",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								business_gas_station: {
										label: this.$t('תחנות דלק'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.business.gas_station",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								business_car_rental: {
										label: this.$t('השכרת רכב'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.business.car_rental",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								business_lodging: {
										label: this.$t('מלונות וצימרים'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.business.lodging",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								government: {
										label: this.$t('ממשלה'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.government",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								}    ,
								medical: {
										label: this.$t('רפואה'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.medical",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								}    ,
								park: {
										label: this.$t('פארקים'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.park",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								}    ,
								place_of_worship: {
										label: this.$t('בתי כנסת'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.place_of_worship",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								}    ,
								school: {
										label: this.$t('בתי ספר'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.school",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								}    ,
								sports_complex: {
										label: this.$t('אצטדיונים'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "poi.sports_complex",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								transit: {
										label: this.$t('תחבורה ציבורית'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "transit",
												stylers: [
														{
																visibility: "on"
														}
												]
										}
								},
								highway: {
										label: this.$t('כבישים מהירים'),
										visibility: true,
										showInMenu: true,
										mapStyle: {
												featureType: "road.highway",
												elementType: "geometry.fill",
												stylers: [
														{
																color: "#fa0000"
														}
												]
										}
								}
						}
				},
        ...mapGetters(['contentPage', 'isMobile'])
    },
    watch:{
        contentPage(newVal){
            this.updateMapFromPostSettings(newVal)
        }
    },
    data(){
        return {
            loading: false,
            googleMap: null,
            currentLandmark: null,
            customStyle: null,
            allPoi: 'on',
						activeLMG: {},
						activePOI: {}
        }
    },
    methods:{
        updateMapFromPostSettings(post){
            if(post && post.map && post.map.built_in_poi){
                const builtInPoi = post.map.built_in_poi
                Object.entries(this.customStyle).forEach((poi) => {
                    const key = poi[0]
                    //const value = poi[1]
                    if(key && this.customStyle[key]){
                        this.customStyle[key].showInMenu = builtInPoi.findIndex(k => k === key) !== -1
                        /*
                        if(!this.customStyle[key].showInMenu){
                        }*/
                        this.customStyle[key].visibility = false

                        if(key === "highway"){
                            this.customStyle[key].mapStyle.stylers = this.customStyle[key].mapStyle.stylers.map((style) => {
                                style.color = "#4E4E4E"
                                return style
                            })
                        }else{
                            this.customStyle[key].mapStyle.stylers = this.customStyle[key].mapStyle.stylers.map((style) => {
                                style.visibility = "off"
                                return style
                            })
                        }

                    }
                });
            }
        },
        close(){
            this.$router.push({name: 'home'})
        },
        async loadPost(postId){
            this.loading = true
            if(postId){
                await this.$store.dispatch('loadContent', {postId, type: 'area'})
                this.googleMap = this.$refs.googleMap
            }
            this.loading = false
        },
        selectLandmarkGroup(lmgi, landmarkGroup){
						this.activeLMG[lmgi] = landmarkGroup.visible = !landmarkGroup.visible
            this.googleMap.toggleLandmarkGroup(landmarkGroup)
        },
        isCurrentLandmark(landmark){
            return this.currentLandmark === landmark
        },
        filter(poiKey){
            const styles = JSON.parse(JSON.stringify(mapStyleSilver))
            const cs = this.customStyle

            if(cs[poiKey] && cs[poiKey].mapStyle && cs[poiKey].mapStyle.stylers){
                if(poiKey === 'highway'){
                    cs[poiKey].mapStyle.stylers = cs[poiKey].mapStyle.stylers.map((style) => {
                        if(cs[poiKey].visibility){
                            style.color = "#4E4E4E"
                            cs[poiKey].visibility = false
                        }else{
                            style.color = "#fa0000"
                            cs[poiKey].visibility = true
                        }
                        return style
                    })
                }else{
                    cs[poiKey].mapStyle.stylers = cs[poiKey].mapStyle.stylers.map((style) => {
                        if(cs[poiKey].visibility){
                            style.visibility = "off"
														this.activePOI[poiKey] = cs[poiKey].visibility = false
                        }else{
                            style.visibility = "on"
														this.activePOI[poiKey] = cs[poiKey].visibility = true
                        }
                        return style
                    })
                }
            }

            Object.entries(cs).forEach((poi) => {
                const key = poi[0]
                const value = poi[1]
                if(poiKey === 'all'){
                    if(key === 'all'){
                        styles.push(value.mapStyle)
                    }else{
                        cs[key].visibility = cs.all.visibility
                        if(key === 'highway'){
                            cs[key].mapStyle.stylers = cs[key].mapStyle.stylers.map((style) => {
                                if(cs[key].visibility){
                                    style.color = "#4E4E4E"
                                    cs[key].visibility = true
                                }else{
                                    style.color = "#fa0000"
                                    cs[key].visibility = false
                                }
                                return style
                            })
                        }else{
                            cs[key].mapStyle.stylers = JSON.parse(JSON.stringify(this.customStyle.all.mapStyle.stylers))
                        }
                    }
                }else{
                    if(key === 'all' && !value.showInMenu){
                        value.visibility = false
                        value.mapStyle.stylers = value.mapStyle.stylers.map((style) => {
                            style.visibility = "off"
                            return style
                        })
                    }
                    styles.push(value.mapStyle)
                }
            })

            if(this.googleMap && this.googleMap.map){
                this.googleMap.map.setOptions({
                    styles: JSON.parse(JSON.stringify(styles))
                })
            }
        }
    },
    mounted() {
        this.customStyle = this.mapPoi
    },
    beforeRouteEnter(to, from, next) {
        const postId = to.params.postId
        next(vm => {
            vm.loadPost(postId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        const postId = to.params.postId
        this.loadPost(postId)
        next()
    },
    beforeRouteLeave(){
        this.$store.commit('currentContentId', null)
    }
}
</script>

<style scoped lang="scss">
    .page.area {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .area-modal {
        position: relative;
        height: 720px;
        max-height: calc(100vh - 160px);
        width: 1300px;
        max-width: calc(100% - 2rem);
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: stretch;
        flex-flow: row nowrap;

        &.v-enter-active,
        &.v-leave-active {
            transition: transform 1s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateY(100vh);
        }

        .close-btn {
            position: absolute;
            top: 10px;
            left: 5px;
        }

        .area-sidebar {
            padding: 1.25rem 0;
            width: 215px;
            border-left: solid 1px #434646;
            margin: 1.4375rem 0;
            height: calc(100% - 1.4375rem * 2);
            display: flex;
            flex-flow: column;
        }

        .area-content {
            flex-grow: 1;
            padding: 1.875rem 1.125rem 1.5625rem 1.875rem;

            iframe {
                height: 100%;
                width: 100%;
            }
        }

        .area-title {
            text-align: center;
            padding-bottom: 1rem;
            border-bottom: solid 1px #475257;
            margin-bottom: 1.875rem;
            margin-left: 1.25rem;
            margin-right: 1.25rem;
        }

        .nav {
            overflow-x: auto;
        }

        .list-menu {
            :deep(.list-menu-item) {
                padding: 0.4375rem 2.5rem 0.4375rem 0;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                border-left: solid 3px transparent;
                border-top: solid 1px transparent;
                border-bottom: solid 1px transparent;
                gap: .5rem;

                img {
                    width: 26px;
                    height: auto;
                }

                svg {
                    margin-left: 0.625rem;
                }

                &:hover,
                &:focus,
                &.active {
                    color: var(--primary-light);
                    background: linear-gradient(180deg, rgba(179, 179, 179, 0.2) 0%, rgba(179, 179, 179, 0) 128.09%);
                    border-bottom-color: var(--primary-darkerer);
                    border-top-color: var(--primary-darkerer);
                    border-left-color: var(--primary-light);
                }
            }
        }
    }

    .is-mobile {
        .area-modal {
            display: flex;
            flex-flow: column;
            max-width: none;
            width: 100vw;
            height: auto;
            min-height: calc(100vh - 57px - 60px);
            max-height: none;
            border-radius: 0;
            border: none;

            .area-title {
                margin-bottom: 0;
            }

            .area-content {
                padding: 0;
                display: flex;
                flex-flow: column;
                iframe {
                    flex-grow: 1;
                }
            }

            .area-sidebar {
                width: auto;
                height: auto;
                overflow: auto;
                border-left: none;
                margin-bottom: 0;

                .nav {
                    overflow-x: auto;
                    overflow-y: hidden;

                    /* scrollbar design for Chrome, Safari and Opera */
                    &::-webkit-scrollbar {
                        height: 2px;
                        background-color: #aaa;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: var(--primary);
                    }

                    /* scrollbar design for Edge and Firefox */
                    & {
                        scrollbar-width: thin;
                        scrollbar-color: var(--primary) #aaa;
                    }
                }

                .list-menu {
                    display: flex;
                    align-items: center;
                    flex-flow: row nowrap;

                    .list-menu-item {
                        white-space: nowrap;
                        padding-right: 1.25rem;
                        padding-left: 1.25rem;
                        border-left: solid 1px transparent;
                        border-right: solid 1px transparent;
                        border-top: none;
                        border-bottom: solid 3px transparent;

                        &:hover,
                        &:focus {
                            border-right-color: var(--primary-darkerer);
                            border-left-color: var(--primary-darkerer);
                            border-bottom-color: var(--primary-light);
                        }
                    }
                }
            }
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.page.area {
						height: 100%;
						.area-modal {
								max-height: none;
								height: 100%;
								max-width: none;
								border-radius: 0;
								border: none;
								width: 100%;
								backdrop-filter: blur(5px);

								.area-sidebar {
										.list-menu {
												li {
														margin: 0 15px 15px;
												}
												.list-menu-item {
														padding: .5rem;
														border: solid 1px var(--primary-darkerer);
														border-radius: 4px;
														min-height: 48px;

														&:hover,
														&:focus {
																color: #fff;
														}

														&.visible {
																border-color: var(--primary);

																/*&.poi-attraction {
																		border-color: #00ff6b;
																}
																&.poi-business {
																		border-color: #0709be;
																}
																&.poi-business_shopping {
																		border-color: #0709be;
																}
																&.poi-business_food_and_drink {
																		border-color: #5007be;
																}
																&.poi-business_gas_station {
																		border-color: #c04f04;
																}
																&.poi-business_car_rental {
																		border-color: #c04f04;
																}
																&.poi-business_lodging {
																		border-color: #be07a6;
																}
																&.poi-government {
																		border-color: #676767;
																}
																&.poi-medical {
																		border-color: #01b297;
																}
																&.poi-park {
																		border-color: #559a00;
																}
																&.poi-place_of_worship {
																		border-color: #989500;
																}
																&.poi-school {
																		border-color: #a10043;
																}
																&.poi-sports_complex {
																		border-color: #a10043;
																}
																&.poi-transit {
																		border-color: #ff0000;
																}*/
														}

												}
										}
								}
						}
				}
		}
</style>
